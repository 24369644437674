class RecentsPostHome extends HTMLElement {
    constructor(){
        super();

        this.sliderContainer = this.querySelector('.slider-news');
        this.sliderControls = this.querySelector('.container-arrows');
    }

    connectedCallback(){
        tns({
            container: this.sliderContainer,
            mouseDrag: true,
            controlsContainer: this.sliderControls,
            navAsThumbnails: true,
            responsive: {
                320: {
                    fixedWidth: 315,
                    items: 1,
                },
                376: {
                    fixedWidth: false,
                    items: 1,
                },
                560: {
                    fixedWidth: 455,
                    gutter: 20,
                    items: 3,
                }
            },
        });
    }
}
customElements.define('slider-homerecents', RecentsPostHome);
